<template>
  <div id="modelindex">
    <!--内容区域布局-->
    <LiefengContent>
      <!--功能名称，定义则显示-->
      <template v-slot:title>模板定制</template>
       <!--左则工具条-->
      <template v-slot:toolsbarLeft>
        
         <!--社区树-->
         <LiefengTree
          ref="mychild"
          @handleData="getList"
          :fixedmenu="true"
          style="width: 200px"
          treeID="mychild"
          fixedContentID="contentarea"
        >
        </LiefengTree>
                <Input
                  enter-button
                  v-model.trim="searchData.name"
                  placeholder="姓名"
                  style="width: 120px;margin:0 5px;"
                  @on-enter="getList"
                />
                <Input
                  enter-button
                  v-model.trim="searchData.phone"
                  placeholder="手机号"
                  style="width: 120px;margin:0 5px;"
                  @on-enter="getList"
                />

                <Button type="info" icon="ios-search" @click="getList"
                  >查询</Button
                >
                <Button type="success" icon="ios-refresh" 
                  >重置</Button
                >
       
      </template>
       <!--右则工具条-->
      <template v-slot:toolsbarRight>
        <Button type="error" icon="ios-add-circle-outline" @click="showModal=true">导入</Button>
        <Button type="warning" icon="ios-mail" @click="selectModal=true;setEditorContent();">发消息</Button>
        <Button type="primary" icon="ios-search">Search</Button>
      </template>
       <!--内容区域-->
      <template v-slot:contentArea>
         <!--表格应用-->
        <LiefengTable
          :talbeColumns="talbeColumns"
          :tableData="tableData"
          height="200" 
          :loading="loading"
          :fixTable="true"
          :curPage="pageNum"
          :total="total"
          :page-size="pageSize"
          @tableSelect="setSelection"
          @tableSort="tableSort"
          @hadlePageSize="hadlePageSize"
          
        >
        </LiefengTable>
      </template>
    </LiefengContent>

<LiefengModal
     v-model="selectModal"
      title="发消323232323"
      width="80%"
      height="calc(100vh - 250px)"
      id="ueditorContent"
       :fullscreen="true"
    >
    <!--工具条-->
    <template #toolsbar>
       <Button type="primary" @click="getEditorContent">确定</Button>
       <Button style="margin-left: 10px">清空</Button>
    </template>
    <!--内容-->
       <template #contentarea>
      <div style="height:100px;"></div>
     <!-- <iframe name="ueditor" id="ueditor" src="/ueditor/index.html?titlename=活动内容" style="width:100%;height:500px;border:0px;"></iframe> -->
     <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=活动内容"
                    style="width:100%;height:500px;border:0px;"
              ></iframe>

    </template>
</LiefengModal>
    <LiefengModal
     v-model="showModal"
      title="发消息22"
      width="100%"
      height="calc(100vh - 50px)"
      :fullscreen="true"
    >
     <template #toolsbar>
       <Button type="primary" >确定</Button>
       <Button style="margin-left: 10px">清空</Button>
    </template>
       <template #contentarea>
       <ve-line :data="chartData"  width="500px" ></ve-line>
    </template>
    </LiefengModal>
  </div>
</template>
<script>
//@route('/modelindex')
import LiefengContent from "@/components/LiefengContent.vue";
import LiefengTree from "@/components/LiefengTree.vue";
import LiefengTable from "@/components/LiefengTable.vue";
import LiefengModal from "@/components/LiefengModal.vue";
import modelFrom from "@/views/model/childrens/modelForm.vue";
import VeLine from 'v-charts/lib/line.common';
export default {
  name: "modelindex",
  components: {
    LiefengContent,
    LiefengTable,
    LiefengTree,
    LiefengModal,
    modelFrom,
    VeLine,
  },
  data() {
    return {
      chartData: {
          columns: ['日期', '访问用户', '下单用户', '下单率'],
          rows: [
            { '日期': '1/1', '访问用户': 1393, '下单用户': 1093, '下单率': 0.32 },
            { '日期': '1/2', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
            { '日期': '1/3', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
            { '日期': '1/4', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
            { '日期': '1/5', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
            { '日期': '1/6', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 }
          ]
        },
      loading:false,
      total: 0,
      treeData: [],
      showModal:false,
      selectModal: false,
      msgform: {
        businessCode: "0000", //业务代码 0000:发送系统信息
        businessType: 5, // 业务类型5:发送系统信息
        oemCode: parent.vue.loginInfo.userinfo.oemCode,
        recIds: "",
        title: "",
        content: "",
        sendId: parent.vue.loginInfo.userinfo.custGlobalId,
      },
     
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "住房编号",
          key: "familyNo",
          width: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 100,
          sortable:"custom",
        },
        {
          title: "性别",
          key: "sexLabel",
          width: 80,
        },
        {
          title: "联系电话",
          key: "phone",
          width: 130,
          align: "center",
        },
        {
          title: "身份类型",
          key: "userTypeLabel",
          width: 120,
        },
      
        {
          title: "入驻时间",
          key: "gmtCreate",
          width: 180,
        },
      
        {
          title: "所在详细地址",
          key: "detailAddr",
  
          align: "center",
          render: (h, params) => {
            return h("div", 
                params.row.roadName + params.row.detailAddr
            );
          },
        },

        {
          title: "是否家庭联络人",
          key: "contactLabel",
          align: "center",
        },

        {
          title: "操作",
          width: 80,
          render: (h, params) => {
            return h("Button", 
                {
                  props:{type:"primary",size:"small"},
                  style:{},
                  on:{

                  }
                }
            ,"删除");
          },
        },
      ],
      tableData: [],
      selection: [],
      showDo: true,
      hideEdit: true,
      hidenBread: true,
      hideDel: false,
      hideSearch: false,
      pageNum: 1,
      pageSize: 3,
      orgCode: "",
      searchData: {
        name: "",
        phone: "",
        pageSize: 3,
        pageNum: 1,
      },
      selectId: [],
      selectName: "",
      ruleValidate: {
        recIds: [
          {
            required: true,
            message: "接收人不能为空",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "内容不能为空",
            trigger: "blur",
          },
        ],
      },
      treeParams: {
        status: 2, //必填
        roadCode: "",
        projectCode: "",
        communityCode: "", //必填
        pageSize: 3,
        page: 1,
        name: "",
        phone: "",
        oemCode: "", //必填  缓存拿
      },
      pagess: 1,
    };
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
  },
  mounted() {
  },
  watch: {
    
  },
  methods: {
    getList(data) {
     
      if (data) {
        if (data.communityCode) {
          this.tableData = [];
          this.pageNum = 1;
          this.treeParams = {
            ...data,
            status: 2, //必填
            name: this.searchData.name,
            phone: this.searchData.phone,
            oemCode: parent.vue.loginInfo.userinfo.oemCode,
            page: data.page,
            pageSize: data.pageSize,
          };
          this.pageNum = data.page;
          this.pageSize = data.pageSize;
        }
        if (data.page) {
          //切换页面时
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          // this.treeParams.name = this.searchData.name;
          // this.treeParams.phone = this.searchData.phone;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.pageNum = 1;
        }
      } else {
        this.treeParams = {
          communityCode: this.treeParams.communityCode,
          roadCode: this.treeParams.roadCode,
          status: 2, //必填
          name: this.searchData.name,
          phone: this.searchData.phone,
          oemCode: parent.vue.loginInfo.userinfo.oemCode,
          page: this.pageNum,
          pageSize: this.pageSize,
        };
      }
      this.loading=true;
      // console.log(this.treeParams)
      this.$get(
        "/syaa/pc/sy/user/residents/selectSyFamilyMemberPage",
        this.treeParams
      )
        .then((res) => {
          this.loading=false;
          if (res.code === "200") {
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.pageNum = res.currentPage;
            this.treeParams.name = "";
            this.treeParams.phone = "";
          }
        })
        .catch((err) => {
          this.loading=false;
          console.log(err);
        });
    },

    setSelection(selection) {
      console.log(selection);
      this.selection = selection;
    },
    tableSort(sortdata){
       console.log(sortdata);
    },
        hadlePageSize(val) {
          console.log(val);
          this.getList(val);
        },
    
    getTableData(data) {
      this.tableData = data.dataList;
      this.total = data.maxCount;
    },

    setEditorContent(){//加载富文本例子
    var v_Obj=document.getElementById("ueditor").contentWindow;
    v_Obj.setContent('<img src="/images/bg2.jpg">斯巴达<img src="/images/bg3.jpg">');
    },
    getEditorContent(){//获取富文本例子
      var v_Obj=document.getElementById("ueditor").contentWindow;
      console.log(v_Obj.getContent());
    }

  },
};
</script>

<style lang="less" scoped>

form input,form button{
margin:0 5px;
}
</style>
